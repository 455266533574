import { ref, onValue, push, update, remove } from 'firebase/database';
import { useEffect, useState } from 'react';
import { db } from '../firebase';
import { Point } from '../types';

export function usePoints(mapId: string) {
  const [points, setPoints] = useState<Point[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  // Subscribe to real-time updates
  useEffect(() => {
    const pointsRef = ref(db, `maps/${mapId}/points`);

    const unsubscribe = onValue(pointsRef, (snapshot) => {
      try {
        const data = snapshot.val();
        const pointsList = data ? Object.entries(data).map(([id, point]) => ({
          id,
          ...(point as Omit<Point, 'id'>)
        })) : [];
        setPoints(pointsList);
        setLoading(false);
      } catch (err) {
        setError(err as Error);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [mapId]);

  // CRUD operations
  const addPoint = async (point: Omit<Point, 'id'>) => {
    const pointsRef = ref(db, `maps/${mapId}/points`);
    await push(pointsRef, point);
  };

  const updatePoint = async (pointId: string, point: Partial<Point>) => {
    const pointRef = ref(db, `maps/${mapId}/points/${pointId}`);
    await update(pointRef, point);
  };

  const deletePoint = async (pointId: string) => {
    const pointRef = ref(db, `maps/${mapId}/points/${pointId}`);
    await remove(pointRef);
  };

  return {
    points,
    loading,
    error,
    addPoint,
    updatePoint,
    deletePoint
  };
}
import { useEffect, useState } from 'react';
import { User } from 'firebase/auth';
import { auth } from '../firebase';

export function useAuth() {
    const [currentUser, setCurrentUser] = useState<User | null>(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
        });
        return () => unsubscribe();
    }, []);

    return { currentUser };
}
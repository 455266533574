import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, XCircle } from 'lucide-react';
import { YMap } from './YMap';
import { AddPointModal } from './AddPointModal';
import { Point } from '../types';
import { usePoints } from '../hooks/usePoints';
import { useParams } from 'react-router-dom';
import { useMaps } from '../hooks/useMaps';
import { useAuth } from '../hooks/useAuth';

export function MapView() {
  const id = useParams().id || '';
  const { touchMap } = useMaps();
  const { currentUser } = useAuth();
  const [isAddPointModalOpen, setIsAddPointModalOpen] = useState(false);
  const [temporaryLocation, setTemporaryLocation] = useState<[number, number] | null>(null);
  const [longClickedLocation, setLongClickedLocation] = useState<[number, number] | null>(null);
  const [editingPoint, setEditingPoint] = useState<Point | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { points, error: pointsError, addPoint, updatePoint, deletePoint } = usePoints(id);

  useEffect(() => {
    if (!id || !currentUser) return;
    try {
      touchMap(id);
    } catch (err) {
      setErrorMessage(`Failed to load map: ${err}`);
    }
  }, [id, currentUser]);

  const handleMapClick = (coords: [number, number]) => {
  };

  const handlePointClick = (point: Point) => {
  };

  const handleAddPoint = async (point: Point) => {
    try {
      if (point.id) {
        await updatePoint(point.id, point);
      } else {
        await addPoint(point);
      }
      setIsAddPointModalOpen(false);
    } catch (err) {
      setErrorMessage(`Failed to save point: ${err}`);
    }
  };

  const handleMapLongPress = (coords: [number, number]) => {
    setIsAddPointModalOpen(true);
    setLongClickedLocation(coords);
  };

  const handleCloseAddPointModal = () => {
    setIsAddPointModalOpen(false);
    setLongClickedLocation(null);
  };

  const handlePointDelete = async (point: Point) => {
    if (!point.id) return;
    try {
      await deletePoint(point.id);
    } catch (err) {
      setErrorMessage(`Failed to delete point: ${err}`);
    }
  };

  const handlePointEdit = (point: Point) => {
    setIsAddPointModalOpen(true);
    setEditingPoint(point);
  };

  return (
    <div className="relative w-full h-full mt-16 overflow-hidden">
      <YMap
        center={temporaryLocation || [55.751574, 37.573856]}
        zoom={9}
        onMapClick={handleMapClick}
        onMapLongPress={handleMapLongPress}
        points={points}
        onPointClick={handlePointClick}
        temporaryMarker={temporaryLocation}
        onPointDelete={handlePointDelete}
        onPointEdit={handlePointEdit}
      />

      {/* Error Toast */}
      <AnimatePresence>
        {errorMessage && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className="absolute bottom-20 left-1/2 transform -translate-x-1/2 bg-red-500 text-white px-4 py-2 rounded-lg shadow-lg flex items-center gap-2"
            onClick={() => setErrorMessage(null)}
          >
            <XCircle className="w-5 h-5" />
            <span>{errorMessage}</span>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Controls */}
      <div className="absolute bottom-4 right-4">
        <motion.button
          className="w-12 h-12 bg-brand-500 rounded-full shadow-lg flex items-center justify-center"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setIsAddPointModalOpen(true)}
        >
          <Plus className="w-6 h-6 text-white" />
        </motion.button>
      </div>

      <AddPointModal
        isOpen={isAddPointModalOpen}
        onClose={handleCloseAddPointModal}
        onSubmit={handleAddPoint}
        onLocationSelect={setTemporaryLocation}
        prefillLocation={longClickedLocation}
        initialPoint={editingPoint}
      />

      {pointsError && (
        <div className="absolute bottom-20 left-1/2 transform -translate-x-1/2 bg-red-500 text-white px-4 py-2 rounded-lg shadow-lg flex items-center gap-2">
          <XCircle className="w-5 h-5" />
          <span>{pointsError.message}</span>
        </div>
      )}
    </div>
  );
}
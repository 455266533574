import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { MapList } from './components/MapList';
import { MapView } from './components/MapView';
import { TopBar } from './components/TopBar';
import { LoginScreen } from './components/LoginScreen';
import { auth } from './firebase';

function App() {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen bg-surface-50 flex items-center justify-center">
        <div className="animate-pulse text-surface-400">Loading...</div>
      </div>
    );
  }

  if (!user) {
    return <LoginScreen />;
  }

  return (
    <BrowserRouter>
      <div className="h-[100dvh] safe-top bg-surface-50 flex flex-col">
        <TopBar />
        <Routes>
          <Route
            path="/"
            element={
              <main className="flex-1">
                <MapList />
              </main>
            }
          />
          <Route
            path="/map/:id"
            element={<MapView />}
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;

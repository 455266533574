import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { LogIn, Mail } from 'lucide-react';
import { signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';

export function LoginScreen() {
  const [isRegistering, setIsRegistering] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Login failed:', error);
      setError('Google login failed. Please try again.');
    }
  };

  const handleEmailAuth = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    try {
      if (isRegistering) {
        await createUserWithEmailAndPassword(auth, email, password);
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <div className="min-h-screen bg-surface-50 flex items-center justify-center p-4">
      <motion.div
        className="bg-white p-8 rounded-2xl shadow-lg max-w-sm w-full"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <h1 className="text-2xl font-semibold text-brand-600 text-center mb-8">LocShare</h1>
        <p className="text-surface-600 text-center mb-8">
          Create and share maps with your friends
        </p>

        <form onSubmit={handleEmailAuth} className="space-y-4 mb-4">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500"
          />
          {error && <p className="text-red-500 text-sm">{error}</p>}
          <button
            type="submit"
            className="w-full bg-brand-600 text-white py-3 px-4 rounded-lg flex items-center justify-center gap-2 hover:bg-brand-700 transition-colors"
          >
            <Mail className="w-5 h-5" />
            {isRegistering ? 'Register' : 'Sign In'}
          </button>
        </form>

        <button
          onClick={handleGoogleLogin}
          className="w-full bg-surface-800 text-white py-3 px-4 rounded-lg flex items-center justify-center gap-2 hover:bg-surface-700 transition-colors"
        >
          <LogIn className="w-5 h-5" />
          Continue with Google
        </button>

        <button
          onClick={() => setIsRegistering(!isRegistering)}
          className="w-full text-surface-600 mt-4 text-sm hover:text-surface-800"
        >
          {isRegistering ? 'Already have an account? Sign in' : "Don't have an account? Register"}
        </button>
      </motion.div>
    </div>
  );
}
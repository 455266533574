import React, { useEffect, useState } from 'react';
import { X, Copy, Check } from 'lucide-react';
import QRCode from 'qrcode';

interface ShareModalProps {
  shareUrl: string;
  onClose: () => void;
}

export function ShareModal({ shareUrl, onClose }: ShareModalProps) {
  const [qrCode, setQrCode] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    QRCode.toDataURL(shareUrl)
      .then(url => setQrCode(url))
      .catch(err => console.error(err));
  }, [shareUrl]);

  const copyLink = async () => {
    await navigator.clipboard.writeText(shareUrl);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md mx-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Share Location</h2>
          <button onClick={onClose} className="p-1">
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="flex justify-center mb-6">
          {qrCode && (
            <img src={qrCode} alt="QR Code" className="w-48 h-48" />
          )}
        </div>

        <div className="flex items-center gap-2 bg-gray-100 p-2 rounded">
          <input
            type="text"
            value={shareUrl}
            readOnly
            className="flex-1 bg-transparent outline-none"
          />
          <button
            onClick={copyLink}
            className="p-2 hover:bg-gray-200 rounded transition-colors"
          >
            {copied ? <Check className="w-5 h-5 text-green-600" /> : <Copy className="w-5 h-5" />}
          </button>
        </div>
      </div>
    </div>
  );
}
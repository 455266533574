import React, { useState } from 'react';
import { Edit, MapPin, Plus, Trash2, Share2, Archive, EyeOff } from 'lucide-react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useMaps } from '../hooks/useMaps';
import { SharedMap } from '../types';
import { ShareModal } from './ShareModal';
import { auth } from '../firebase';

export function MapList() {
  const navigate = useNavigate();
  const { maps, loading, error, addMap, deleteMap, updateMap, archiveSharedMap } = useMaps();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedMap, setEditedMap] = useState<SharedMap | null>(null);
  const [shareMapId, setShareMapId] = useState<string | null>(null);
  const [deleteMapId, setDeleteMapId] = useState<string | null>(null);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const handleEditMap = () => {
    if (editedMap?.name.trim()) {
      if (editedMap.id) {
        if (maps.find(m => m.id === editedMap.id)?.role === 'owner') {
          updateMap(editedMap.id, { name: editedMap.name.trim() });
        }
      } else {
        addMap(editedMap.name.trim());
      }
      setEditedMap(null);
      setIsModalOpen(false);
    }
  };

  return (
    <div className="img-background">
      <div className="container mx-auto px-4 pt-16 pb-safe-bottom">
        <div className="flex justify-between items-center mt-4">
          <h2 className="text-xl font-semibold text-surface-800">Your Maps</h2>
          <button
            onClick={() => {
              if (!auth.currentUser) return;
              setEditedMap({ name: '', points: {}, createdAt: Date.now(), owner: auth.currentUser.uid });
              setIsModalOpen(true);
            }}
            className="inline-flex items-center gap-2 px-4 py-2 bg-brand-600 text-white rounded-full hover:bg-brand-700 transition-colors"
          >
            <Plus className="w-4 h-4" />
          </button>
        </div>

        <div className="grid gap-4 mt-4">
          {maps.map((map, index) => (
            <motion.div
              key={map.id}
              className="bg-white rounded-lg shadow-sm p-4 border border-surface-200 cursor-pointer hover:border-brand-200"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              whileHover={{ scale: 1.01 }}
              whileTap={{ scale: 0.99 }}
            >
              <div className="flex items-center justify-between">
                <div
                  className="flex items-center gap-3 flex-1"
                  onClick={() => navigate(`/map/${map.id}`)}
                >
                  <div className="w-10 h-10 rounded-full bg-brand-100 flex items-center justify-center">
                    <MapPin className="w-5 h-5 text-brand-600" />
                  </div>
                  <div>
                    <h3 className="font-medium text-surface-800">{map.name}</h3>
                    <p className="text-sm text-surface-500">
                      {Object.keys(map.points || {}).length} points
                    </p>
                  </div>
                </div>

                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShareMapId(map.id || null);
                  }}
                  className="p-2 rounded-lg hover:bg-brand-50 transition-colors group"
                  aria-label="Share map"
                >
                  <Share2 className="w-4 h-4 text-surface-400 group-hover:text-brand-600" />
                </button>

                {map.role === 'owner' && (
                  <>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditedMap(map);
                        setIsModalOpen(true);
                      }}
                      className="p-2 rounded-lg hover:bg-brand-50 transition-colors group"
                      aria-label="Edit map"
                    >
                      <Edit className="w-4 h-4 text-surface-400 group-hover:text-brand-600" />
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (map.id) setDeleteMapId(map.id);
                      }}
                      className="p-2 rounded-lg hover:bg-red-50 transition-colors group"
                      aria-label="Delete map"
                    >
                      <Trash2 className="w-4 h-4 text-surface-400 group-hover:text-red-500" />
                    </button>
                  </>
                )}
                {map.role === 'viewer' && (
                  <>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (map.id) archiveSharedMap(map.id);
                      }}
                      className="p-2 rounded-lg hover:bg-brand-50 transition-colors group"
                      aria-label="Edit map"
                    >
                      <EyeOff className="w-4 h-4 text-surface-400 group-hover:text-brand-600" />
                    </button>
                  </>
                )}
              </div>
            </motion.div>
          ))}
        </div>

        {isModalOpen && editedMap && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-lg p-6 w-96">
              <h3 className="text-lg font-semibold mb-4">Create New Map</h3>
              <input
                type="text"
                value={editedMap?.name || ''}
                onChange={(e) => setEditedMap({ ...editedMap, name: e.target.value })}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleEditMap();
                }}
                placeholder="Enter map name"
                className="w-full px-3 py-2 border rounded-lg mb-4"
                autoFocus
              />
              <div className="flex justify-end gap-2">
                <button
                  onClick={() => {
                    setIsModalOpen(false);
                    setEditedMap(null);
                  }}
                  className="px-4 py-2 text-surface-600 hover:bg-surface-100 rounded-lg"
                >
                  Cancel
                </button>
                <button
                  onClick={handleEditMap}
                  className="px-4 py-2 bg-brand-600 text-white rounded-lg hover:bg-brand-700"
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        )}

        {shareMapId && (
          <ShareModal
            shareUrl={`${window.location.origin}/map/${shareMapId}`}
            onClose={() => setShareMapId(null)}
          />
        )}

        {deleteMapId && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 w-96">
              <h3 className="text-lg font-semibold mb-4">Delete Map</h3>
              <p className="text-surface-600 mb-6">Are you sure you want to delete this map? This action cannot be undone.</p>
              <div className="flex justify-end gap-2">
                <button
                  onClick={() => setDeleteMapId(null)}
                  className="px-4 py-2 text-surface-600 hover:bg-surface-100 rounded-lg"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    if (deleteMapId) deleteMap(deleteMapId);
                    setDeleteMapId(null);
                  }}
                  className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
import { useEffect, useRef, useCallback } from 'react';
import { Point } from '../types';

interface YMapProps {
  center: [number, number];
  zoom: number;
  onMapClick?: (coords: [number, number]) => void;
  onMapLongPress?: (coords: [number, number]) => void;
  points?: Point[];
  onPointClick?: (point: Point) => void;
  temporaryMarker?: [number, number] | null;
  onPointDelete?: (point: Point) => void;
  onPointEdit?: (point: Point) => void;
}

export function YMap({ center, zoom, onMapClick, onMapLongPress, points, onPointClick, temporaryMarker, onPointDelete, onPointEdit }: YMapProps) {
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstanceRef = useRef<ymaps.Map | null>(null);
  const markersRef = useRef<any[]>([]);

  const updateMarkers = useCallback(() => {
    markersRef.current.forEach(marker => {
      mapInstanceRef.current?.geoObjects.remove(marker);
    });
    markersRef.current = [];

    points?.forEach(point => {
      const marker = new (window as any).ymaps.Placemark(point.location, {
        balloonContentHeader: point.name,
        balloonContentBody: `
          <p>${point.address}</p>
          <div style="margin-top: 10px;">
            <button
              id="edit-${point.location.join('-')}"
              style="background: #4B5563; color: white; padding: 4px 8px; border-radius: 4px; margin-right: 8px;"
            >
              Edit
            </button>
            <button
              id="delete-${point.location.join('-')}"
              style="background: #EF4444; color: white; padding: 4px 8px; border-radius: 4px;"
            >
              Delete
            </button>
          </div>
        `
      }, {
        preset: 'islands#orangeDotIcon'
      });

      marker.events.add('balloonopen', () => {
        setTimeout(() => {
          const editBtn = document.getElementById(`edit-${point.location.join('-')}`);
          const deleteBtn = document.getElementById(`delete-${point.location.join('-')}`);

          editBtn?.addEventListener('click', () => {
            marker.balloon.close();
            onPointEdit?.(point);
          });

          deleteBtn?.addEventListener('click', () => {
            marker.balloon.close();
            onPointDelete?.(point);
          });
        }, 100);
      });

      marker.events.add('click', () => onPointClick?.(point));
      mapInstanceRef.current?.geoObjects.add(marker);
      markersRef.current.push(marker);
    });
  }, [points, onPointClick, onPointDelete, onPointEdit]);

  useEffect(() => {
    if (mapInstanceRef.current) {
      updateMarkers();
    }
  }, [points]);

  useEffect(() => {
    // Wait for ymaps to be available
    if (!(window as any).ymaps) {
      console.error('Yandex Maps API not loaded');
      return;
    }

    // Initialize map
    (window as any).ymaps.ready(() => {
      if (mapRef.current && !mapInstanceRef.current) {
        const ymaps = (window as any).ymaps;
        mapInstanceRef.current = new ymaps.Map(mapRef.current, {
          center: center,
          zoom: zoom,
          controls: ['geolocationControl']
        });

        // Define OSM layer constructor
        const osmLayer = function () {
          return new ymaps.Layer('https://tile.openstreetmap.org/%z/%x/%y.png', {
            projection: ymaps.projection.sphericalMercator
          });
        };

        // Register OSM layer (not MQLayer)
        ymaps.layer.storage.add('osm#map', osmLayer);
        const osmType = new ymaps.MapType('OSM', ['osm#map']);
        ymaps.mapType.storage.add('osm#map', osmType);

        mapInstanceRef.current!.controls.add('rulerControl', { position: { left: 50, top: 10 } });
        mapInstanceRef.current!.controls.add(new ymaps.control.TypeSelector({
          mapTypes: ['yandex#map', 'yandex#hybrid', 'osm#map']
        }));

        if (onMapLongPress) {
          mapInstanceRef.current!.events.add('dblclick', (e: any) => {
            const coords = e.get('coords') as [number, number];
            onMapLongPress(coords);
            e.preventDefault();
          });
        }

        if (onMapClick) {
          mapInstanceRef.current!.events.add('click', (e: any) => {
            const coords = e.get('coords') as [number, number];
            onMapClick(coords);
          });
        }

        updateMarkers();
      }
    });

    // Cleanup
    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.destroy();
        mapInstanceRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    const ymaps = (window as any).ymaps;

    if (!mapInstanceRef.current || !temporaryMarker) return;

    const tempPlacemark = new ymaps.Placemark(temporaryMarker, {}, {
      preset: 'islands#redDotIcon',
      draggable: false
    });

    mapInstanceRef.current.geoObjects.add(tempPlacemark);
    mapInstanceRef.current.setCenter(temporaryMarker);
    mapInstanceRef.current.setZoom(17);

    return () => {
      mapInstanceRef.current?.geoObjects.remove(tempPlacemark);
    };
  }, [mapInstanceRef, temporaryMarker]);

  return <div ref={mapRef} className="w-full h-full" />;
}
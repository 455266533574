import React, { useState, useEffect, useRef } from 'react';
import { Search, X, Check } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Point } from '../types';

interface AddPointModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (point: Point) => void;
  prefillLocation: [number, number] | null;
  onLocationSelect?: (location: [number, number] | null) => void;
  initialPoint: Point | null;
}

export function AddPointModal({
  isOpen,
  onClose,
  onSubmit,
  prefillLocation,
  onLocationSelect,
  initialPoint
}: AddPointModalProps) {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [selectedLocation, setSelectedLocation] = useState<[number, number] | null>(null);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const locationInputRef = useRef<HTMLInputElement>(null);
  const [isLocationFocused, setIsLocationFocused] = useState(false);

  // Initialize form with existing point data if provided
  useEffect(() => {
    if (initialPoint) {
      setName(initialPoint.name);
      setAddress(initialPoint.address);
      setSelectedLocation(initialPoint.location);
    }
  }, [initialPoint]);

  useEffect(() => {
    const ymaps = (window as any).ymaps;
    if (!locationInputRef.current || !ymaps) return;

    // Initialize Yandex Maps search
    ymaps.ready(() => {
      const suggestView = new ymaps.SuggestView(locationInputRef.current);

      suggestView.events.add('select', (e: any) => {
        const selectedItem = e.get('item');
        geocodeAddress(selectedItem.value);
      });
    });

    if (prefillLocation) {
      nameInputRef.current?.focus();
      ymaps.geocode(prefillLocation).then((result: any) => {
        const firstGeoObject = result.geoObjects.get(0);
        const address = firstGeoObject.getAddressLine() || prefillLocation.join(', ');
        setAddress(address);
      }).catch((err: any) => {
        console.error(err);
        setAddress(prefillLocation.join(', '));
      });
    } else {
      locationInputRef.current.focus();
    }
  }, [locationInputRef.current, nameInputRef.current]);

  // Add geocoding function
  const geocodeAddress = (value: string) => {
    (window as any).ymaps.geocode(value).then((result: any) => {
      const firstGeoObject = result.geoObjects.get(0);
      const coords = firstGeoObject.geometry.getCoordinates();
      setAddress(value);
      setSelectedLocation(coords);
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      geocodeAddress(address);
    }
  };

  useEffect(() => {
    if (prefillLocation) {
      setSelectedLocation(prefillLocation);
      setAddress(prefillLocation.join(', '));
    } else {
      setSelectedLocation(null);
      setAddress('');
    }
  }, [prefillLocation]);

  useEffect(() => {
    onLocationSelect?.(selectedLocation);
  }, [selectedLocation, onLocationSelect]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedLocation) return;

    onSubmit({
      ...(initialPoint || {}), // Preserve existing point data
      name,
      address,
      location: selectedLocation,
    });

    // Reset form
    setName('');
    setAddress('');
    setSelectedLocation(null);
    onClose();
  };

  const handleClose = () => {
    setSelectedLocation(null);
    onLocationSelect?.(null);
    onClose();
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            className="fixed inset-0 bg-black/0 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={handleClose}
          />
          <motion.div
            className={`fixed left-0 right-0 bottom-0 bg-white p-4 z-50 max-w-lg mx-auto ${
              isLocationFocused ? 'h-[120px]' : ''
            }`}
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 100, opacity: 0 }}
          >
            <AnimatePresence>
              {!isLocationFocused && (
                <motion.div
                  initial={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="flex justify-between items-center mb-4"
                >
                  <h3 className="text-lg font-semibold text-surface-800">
                    {initialPoint ? 'Edit Point' : 'Add New Point'}
                  </h3>
                  <button onClick={handleClose}>
                    <X className="w-5 h-5 text-surface-400" />
                  </button>
                </motion.div>
              )}
            </AnimatePresence>

            <form onSubmit={handleSubmit} className="space-y-4">
              <AnimatePresence>
                {!isLocationFocused && (
                  <motion.div
                    initial={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                  >
                    <label htmlFor="name" className="block text-sm font-medium text-surface-700 mb-1">
                      Point Name
                    </label>
                    <input
                      ref={nameInputRef}
                      type="text"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="w-full px-3 py-2 border border-surface-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent"
                      placeholder="Enter point name..."
                      required
                    />
                  </motion.div>
                )}
              </AnimatePresence>

              <div>
                {!isLocationFocused && (
                  <label htmlFor="address" className="block text-sm font-medium text-surface-700 mb-1">
                    Location
                  </label>
                )}
                <div className="relative">
                  <input
                    ref={locationInputRef}
                    type="text"
                    id="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onFocus={() => setIsLocationFocused(true)}
                    onBlur={() => setIsLocationFocused(false)}
                    className={`w-full pl-10 ${isLocationFocused ? 'pr-10' : 'pr-3'} py-2 border border-surface-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-transparent`}
                    placeholder={isLocationFocused ? "Search location..." : "Enter location..."}
                    required
                  />
                  <Search className="w-5 h-5 text-surface-400 absolute left-3 top-1/2 -translate-y-1/2" />
                  {isLocationFocused && (
                    <button>
                      <Check className="w-5 h-5 text-surface-400 absolute right-3 top-1/2 -translate-y-1/2" />
                    </button>
                  )}
                </div>
              </div>

              <AnimatePresence>
                {!isLocationFocused && (
                  <motion.button
                    initial={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    type="submit"
                    className="w-full bg-brand-500 text-white py-2 rounded-lg hover:bg-brand-600 transition-colors"
                    disabled={!selectedLocation}
                  >
                    {initialPoint ? 'Save Changes' : 'Add Point'}
                  </motion.button>
                )}
              </AnimatePresence>
            </form>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}
import { useEffect, useState } from 'react';
import { ref, onValue, push, remove, update, get } from 'firebase/database';
import { db } from '../firebase';
import { SharedMap, UserMapAccess } from '../types';
import { useAuth } from './useAuth';

export function useMaps() {
  const [maps, setMaps] = useState<(SharedMap & UserMapAccess)[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser) return;

    const userMapsRef = ref(db, `userMaps/${currentUser.uid}`);
    let mapUnsubscribes: (() => void)[] = [];

    const unsubscribe = onValue(userMapsRef, async (snapshot) => {
      try {
        const userMaps = snapshot.val() as Record<string, UserMapAccess> | null;

        // Clean up existing map listeners
        mapUnsubscribes.forEach(unsub => unsub());
        mapUnsubscribes = [];

        if (!userMaps) {
          setMaps([]);
          setLoading(false);
          return;
        }

        // Set up listeners for each map
        const mapsArray = await Promise.all(Object.entries(userMaps)
          .map(async ([mapId, access]) => {
            const mapRef = ref(db, `maps/${mapId}`);

            // Set up real-time listener for this map
            const mapUnsubscribe = onValue(mapRef, (mapSnapshot) => {
              const mapData = mapSnapshot.val() as SharedMap | null;
              if (!mapData) return;

              setMaps(prevMaps => {
                const otherMaps = prevMaps.filter(m => m.id !== mapId);
                return [...otherMaps, {
                  id: mapId,
                  ...mapData,
                  ...access,
                }].sort((a, b) => (b.lastVisited || 0) - (a.lastVisited || 0));
              });
            });

            mapUnsubscribes.push(mapUnsubscribe);

            const mapSnapshot = await get(mapRef);
            const mapData = mapSnapshot.val() as SharedMap | null;
            if (!mapData) return null;

            return {
              id: mapId,
              ...mapData,
              ...access,
            };
          }));

        const filteredMaps = mapsArray.filter(map => map !== null) as (SharedMap & UserMapAccess)[];

        setMaps(filteredMaps.sort((a, b) => (b.lastVisited || 0) - (a.lastVisited || 0)));
        setLoading(false);
      } catch (err) {
        setError(err as Error);
        setLoading(false);
      }
    });

    return () => {
      unsubscribe();
      mapUnsubscribes.forEach(unsub => unsub());
    };
  }, [currentUser]);

  const addMap = async (name: string) => {
    if (!currentUser) return;

    const mapsRef = ref(db, 'maps');
    const newMapRef = await push(mapsRef, {
      name,
      points: {},
      createdAt: Date.now(),
      owner: currentUser.uid,
    });

    // Add to user's maps
    const userMapRef = ref(db, `userMaps/${currentUser.uid}/${newMapRef.key}`);
    await update(userMapRef, {
      lastVisited: Date.now(),
      role: 'owner',
    });
  };

  const deleteMap = async (mapId: string) => {
    if (!currentUser) return;

    // Only owners can delete maps
    const map = maps.find(m => m.id === mapId);
    if (!map || map.role !== 'owner') return;

    const mapRef = ref(db, `maps/${mapId}`);
    await remove(mapRef);

    // Remove from user maps
    const userMapRef = ref(db, `userMaps/${currentUser.uid}/${mapId}`);
    await remove(userMapRef);
  };

  const updateMap = async (mapId: string, updates: Partial<SharedMap>) => {
    if (!currentUser) return;

    // Only owners can update maps
    const map = maps.find(m => m.id === mapId);
    if (!map || map.role !== 'owner') return;

    const mapRef = ref(db, `maps/${mapId}`);
    await update(mapRef, updates);
  };

  const touchMap = async (mapId: string) => {
    if (!currentUser) return;

    // Check if map exists
    const mapRef = ref(db, `maps/${mapId}`);
    const mapSnapshot = await get(mapRef);
    if (!mapSnapshot.exists()) throw new Error('Map not found');

    const mapData = mapSnapshot.val() as SharedMap;
    const role = mapData.owner === currentUser.uid ? 'owner' : 'viewer';

    // Add to user's maps
    const userMapRef = ref(db, `userMaps/${currentUser.uid}/${mapId}`);
    await update(userMapRef, {
      lastVisited: Date.now(),
      role,
    });
  };

  const archiveSharedMap = async (mapId: string) => {
    if (!currentUser) return;

    const mapRef = ref(db, `userMaps/${currentUser.uid}/${mapId}`);
    const mapSnapshot = await get(mapRef);
    const mapData = mapSnapshot.val() as UserMapAccess;
    if (!mapData) return;
    if (mapData.role !== 'viewer') return;
    await remove(mapRef);
  };

  return { maps, loading, error, addMap, deleteMap, updateMap, touchMap, archiveSharedMap };
}

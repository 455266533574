import React, { useState, useRef, useEffect } from 'react';
import { Share2, ArrowLeft, Share, LogOut } from 'lucide-react';
import { motion } from 'framer-motion';
import { ShareModal } from './ShareModal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { auth } from '../firebase';

export function TopBar() {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const currentUser = auth.currentUser;

  const isMapPage = location.pathname.startsWith('/map/');
  const currentMapId = isMapPage ? params.id : null;

  const handleLogout = () => {
    auth.signOut();
    setIsMenuOpen(false);
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <>
      <motion.header
        className="fixed top-0 left-0 right-0 h-16 bg-white border-b border-surface-200 px-4 z-50"
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
      >
        <div className="h-full flex items-center justify-between">
          {isMapPage ? (
            <button onClick={() => navigate(-1)}>
              <ArrowLeft className="w-6 h-6 text-surface-600" />
            </button>
          ) : (
            <div className="w-6" />
          )}
          <h1 className="text-lg font-semibold text-brand-600">LocShare</h1>
          <div className="flex items-center gap-4">
            {isMapPage && (
              <button onClick={() => setIsShareModalOpen(true)}>
                <Share2 className="w-6 h-6 text-brand-600" />
              </button>
            )}
            <div className="relative" ref={menuRef}>
              {currentUser?.photoURL ? (
                <img
                  src={currentUser.photoURL}
                  alt={currentUser.displayName || 'User'}
                  className="w-8 h-8 rounded-full cursor-pointer"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                />
              ) : (
                <button
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className="text-surface-600 hover:text-surface-800"
                >
                  <LogOut className="w-5 h-5" />
                </button>
              )}

              {isMenuOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 border border-surface-200">
                  <div className="px-4 py-2 border-b border-surface-200">
                    <div className="font-medium">{currentUser?.displayName}</div>
                    <div className="text-sm text-surface-600">{currentUser?.email}</div>
                  </div>
                  <button
                    onClick={handleLogout}
                    className="w-full px-4 py-2 text-left text-surface-800 hover:bg-surface-100 flex items-center gap-2"
                  >
                    <LogOut className="w-4 h-4" />
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </motion.header>

      {isShareModalOpen && currentMapId && (
        <ShareModal
          shareUrl={`${window.location.origin}/map/${currentMapId}`}
          onClose={() => setIsShareModalOpen(false)}
        />
      )}
    </>
  );
}